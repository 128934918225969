import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ProfileContext } from '../../../contexts/ProfileContext';

import { BackIcon } from '../../icons';
import { Link } from 'react-router-dom';
import { CheckProfileProgress } from '../../../utils/utils';
import { getTranslation } from '../../../utils';

export default function Header({ rightSubmitBtn = null, orgPrefix }) {
  const location = useLocation();

  const { t } = useTranslation();

  const [profile] = useContext(ProfileContext);
  const { masterData: { customConfigurations: { colors, buttonRadius } = {} } = {} } = profile;

  const [heading, setHeading] = useState('');
  const [progress, setProgress] = useState([0, 0, 0]);
  const [backLink, setBackLink] = useState('');

  useEffect(() => {
    try {
      if (location.pathname.toLowerCase().includes('video')) {
        let videoNumber =
          Number(location.pathname.split('/video/')[1]) ||
          Number(location.pathname.split('/video-tips/')[1]);

        if (isNaN(videoNumber)) {
          setBackLink();
          return;
        }

        try {
          const activeVideos = profile?.profileTypeData?.videosInformation?.filter(
            (v) => v?.isActive
          );
          const currentVideoNumber = activeVideos.findIndex(
            (v) => Number(v?.videoID) === Number(videoNumber)
          );
          setHeading(getTranslation(t, 'header.video', orgPrefix) + ' ' + (currentVideoNumber + 1));
        } catch (error) {
          setHeading(getTranslation(t, 'header.video', orgPrefix));
          console.log('error', error);
        }

        if (videoNumber === 1) setBackLink('/my-profile');
        else setBackLink(`/video/${parseInt(videoNumber) - 1}`);
      } else {
        setHeading(getTranslation(t, 'header.myProfile', orgPrefix));
        setBackLink('');
      }
    } catch (error) {
      console.log('error', error);
    }
  }, [location, t]);

  useEffect(() => {
    if (!profile?.loaded) return;

    const { totalSteps, progress } = CheckProfileProgress(profile);

    let progressArray = [];
    for (let i = 0; i < totalSteps; i++) {
      if (progress > i) progressArray.push(1);
      else progressArray.push(0);
    }

    setProgress(progressArray);
  }, [profile]);

  return (
    <div className="fixed top-0 left-0 w-full z-50 px-10 pt-12 pb-6 bg-white shadow-xl flex items-center justify-between">
      <div className="flex flex-col gap-1">
        {backLink && (
          <Link to={backLink}>
            <BackIcon />
          </Link>
        )}

        <p className="text-xl text-black font-bold" dangerouslySetInnerHTML={{ __html: heading }} />
      </div>

      {profile.profileType === '642eee66db270f13745908a3' &&
        profile.organization === '670f8010e6f4147369b330a0' && (
          <a
            href="https://inscriptions.worldskills-france.org/"
            className={`text-white px-4 py-2 font-bold flex items-center justify-center disabled:opacity-60`}
            style={{ backgroundColor: colors?.primary, borderRadius: buttonRadius }}>
            Retour Au Portail
          </a>
        )}

      {!rightSubmitBtn && (
        <div className="block">
          <p
            className="text-sm text-progress-100"
            dangerouslySetInnerHTML={{ __html: getTranslation(t, 'header.progress', orgPrefix) }}
          />
          <div className="flex gap-1">
            {progress.map((item, index) => (
              <div
                key={index}
                className={`w-2 h-2 ${item ? 'bg-progress-100' : 'bg-progress-200'} rounded-full`}
              />
            ))}
          </div>
        </div>
      )}
      {rightSubmitBtn && <div className="block">{rightSubmitBtn}</div>}
    </div>
  );
}
